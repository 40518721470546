<template>
	<div>
		<v-row v-if="reservation" style="margin: 10px">
			<v-col style="max-width: 1700px" class="mx-auto">
				<ReservationForm :reservation="reservation" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Vue from "vue";

import ReservationForm from "../components/ReservationForm";

import { getReservations } from "../api/reservations";

export default Vue.extend({
	name: "ReservationDetail",
	components: {
		ReservationForm,
	},
	created() {
		this.handleGetReservation();
	},
	watch: {
		"$route.params.id": function () {
			this.handleGetReservation();
		},
	},
	data() {
		return {
			reservation: null,
		};
	},
	methods: {
		async handleGetReservation() {
			const response = await getReservations(0, 1, null, null, { search: this.$route.params.id });

			if (response.status === 200) {
				this.reservation = response.data.reservations[0];
			} else {
				this.$router.replace("/reservations");
			}
		},
	},
});
</script>
